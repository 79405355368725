import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/foundation-5-new-features",
  "date": "2014-05-04",
  "title": "FOUNDATION 5 NEW FEATURES",
  "author": "admin",
  "tags": ["development", "css", "sass"],
  "featuredImage": "feature.jpg",
  "excerpt": "ZURB’s Foundation framework is a feature-rich, easy to implement,  light weight framework for designing responsive web applications. Designed with mobile-first approach in mind,  it comes packed with CSS3 media queries, The Grid, reusable HTML5 components and several jQuery plugins to aid interactivity."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Foundation is built on Sass: fast tracking the development effort with constant deployment, semantic code, faster styling and few other programmatic features. Let's look at Foundation 5 new features.`}</p>
    <h2>{`Loads Content Faster`}</h2>
    <p>{`Interchange in Foundation, helps developers to create optimized sites by letting the developers to selectively load sections based on the client device type. Interchange uses media queries to dynamically load responsive content that is appropriate for different user's browsers.`}</p>
    <p>{`Interchange can now pull in HTML partials so you can load different sections of your page for particular media queries. This is great for loading up a mobile-friendly component on small devices, and a more robust or heavier load component on desktops.`}</p>
    <p>{`Using Interchange with images alone is the same process as arbitrary HTML content, and a common use case for Interchange.  This way you'll only load larger resources for devices that can handle it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--  larger screens -->
<img alt="deafult" data-interchange="[/path/to/default.jpg, (default)], [/path/to/bigger-image.jpg, (large)]" />

<!-- data-interchange="[image_path, (media query)], [image_path, (media query)]"-->
<img alt="deafult" 
  data-interchange="[/path/to/default.jpg, (only screen and (min-width: 1px))], [/path/to/bigger-image.jpg, (only screen and (min-width: 1280px))]" />

<!-- support browsers with JavaScript disabled -->
<img 
  data-interchange="[/path/to/default.jpg, (default)], [/path/to/bigger-image.jpg, (large)]"> 
  <noscript><img src="/path/to/default.jpg"></noscript>
  <!-- Retina images -->
  <!-- data-interchange="[image/path/to/retina.jpg, (retina)]" -->
`}</code></pre>
    <p>{`Foundation provides following features to further enhance the speed:`}</p>
    <h2>{`Fast Click`}</h2>
    <p>{`Integration of fastclick.js for  mobile users.`}</p>
    <h2>{`GPU Acceleration`}</h2>
    <p>{`Animation will perform significantly better and sleeker.`}</p>
    <h2>{`Off Canvas`}</h2>
    <p>{`Off Canvas provides a powerful way to build navigation into screens.`}</p>
    <h2>{`Improved Forms`}</h2>
    <p>{`Makes it easy to code up forms, in-line labels and other components.`}</p>
    <h2>{`Faster to Code`}</h2>
    <p>{`Foundation now includes a medium grid that you can leverage, nestled neatly between the small and large grids you’re used to. That’s a lot less custom code for you to write to make use of intermediate sizes.`}</p>
    <p><img parentName="p" {...{
        "src": "http://foundation.zurb.com/assets/img/learn/features/medium-grid.svg",
        "alt": "Faster to Code"
      }}></img></p>
    <h2>{`CLI`}</h2>
    <p>{`Foundation has written their own CLI wrappers so you can quickly spin up new projects. Just use `}<strong parentName="p">{`foundation new project-name`}</strong>{`.`}</p>
    <h2>{`Sublime Text`}</h2>
    <p>{`Sublime Text editor now  has Foundation shortcuts.`}</p>
    <h2>{`Templates`}</h2>
    <p>{`Foundation has created new templates to jump start a Foundation 5 project.`}</p>
    <h2>{`Libsassy`}</h2>
    <p>{`Foundation 5 works with `}<strong parentName="p">{`Libsass`}</strong>{`, a new Sass processor. And it’s fast, sometimes 32x as fast.`}</p>
    <h2>{`Medium Grid`}</h2>
    <p>{`Medium sized screens will inherit styles from small, unless you specify a different layout, using the medium grid classes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="row">
  <div class="medium-2 columns">2 columns</div>
  <div class="medium-10 columns">10 columns</div>
</div>
<div class="row">
  <div class="medium-3 columns">3 columns</div>
  <div class="medium-9 columns">9 columns</div>
</div>
`}</code></pre>
    <p>{`By popular request, Foundation now includes a medium grid that you can leverage, nestled neatly between the small and large grids you’re used to. That’s a lot less custom code for you to write to make use of intermediate sizes.`}</p>
    <h2>{`Faster to Learn`}</h2>
    <p>{`Foundation is easy to learn and their Getting Started with Foundation guide is the place you should be.`}</p>
    <p>{`There are few other places you can expand your knowledge of Foundation, and also reuse some of the code.`}</p>
    <h2>{`Building Blocks`}</h2>
    <p>{`Building Blocks are bits of code that you can drop into any Foundation project.`}</p>
    <h2>{`Forum`}</h2>
    <p>{`A custom forum specifically for Foundation users to get the answers they need.`}</p>
    <h2>{`Videos`}</h2>
    <p>{`A premium channel for you to get individual support using Foundation.`}</p>
    <h2>{`Training`}</h2>
    <p>{`ZURB now offers Web-based and on-site training classes for Foundation, taught by the framework developers themselves.`}</p>
    <p><img parentName="p" {...{
        "src": "http://foundation.zurb.com/assets/img/learn/features/getting-started.svg",
        "alt": "Training"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      